@color-text-indicator: #4d4d4dfe;
@indicator-font: 'Raleway Bold', 'Raleway Regular', 'Raleway', sans-serif;

.metric-indicator-wrapper {
  padding: 10px;
  font-family: @indicator-font;
  color: @color-text-indicator;
  font-size: 13px;

  .transparent-content {
    opacity: 0.65;
  }

  .switch-compare-button {
    display: flex;
    justify-content: end;
  }

  .text-center {
    text-align: center;
  }

  .indicator-title {
    font-weight: 600;
    font-size: 14px;
  }

  .card-metric-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .flex-center {
    justify-content: center;
  }

  .flex-end {
    justify-content: end;
  }

  .horizontal-divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid grey;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .vertical-divider {
    height: 100% !important;
    width: 1px !important;
    border-right: 1px solid grey;
    margin-left: 5px;
    margin-right: 5px;
  }

  .indicator-card {
    width: 100%;
    .indicator {
      min-width: 100px;
      padding: 5px;
      text-align: center;
      .indicator-label {
        color: grey;
      }
      .indicator-value {
        font-weight: 600;
        text-overflow: wrap;
      }
    }
  }

  .score-metric {
    margin: 10px;
    .indicator-card {
      min-width: 120px;
    }

    .score-metric-label {
      font-size: 30px;
      font-weight: 600;
    }
    .percent-value {
      font-size: 16px;
    }
  }

  .trending-metric {
    margin: 5px;
    min-width: 120px;

    .score-value {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 3px;
      margin-top: -8px;
    }
    .trending-period {
      margin-bottom: 10px;
      margin-top: -5px;
    }
    .percentage-indicator {
      display: flex;
      .percentage {
        font-size: 30px;
        font-weight: 600;
      }
    }
  }

  .compare-metric-indicator-wrapper {
    .compare-metric-indicator {
      margin-bottom: 20px;
      .indicator-period {
        margin-top: 10px;
        font-weight: 600;
      }
      .indicator-label {
        font-weight: 600;
        text-overflow: wrap;
        font-size: 30px;
      }
      .indicator-variable {
        display: flex;
        justify-content: start;
        margin-top: -5px;

        .variable-item {
          padding-right: 20px;
          margin-right: 20px;
          // min-width: 100px;
          max-width: 150px;
          display: flex;

          &.divider {
            border-right: 1px solid grey;
          }
          .percent-item {
            font-weight: 400;
            font-size: 16px;
            margin-left: 5px;
            max-width: 105px;
          }
          .item-name {
            font-weight: 400;
            // margin-top: -10px;
          }
        }
      }
    }
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;