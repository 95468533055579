@import '~antd/dist/antd.less';
@import url('@base-styles/index.less');

body {
  font-family: 'Roboto', sans-serif;
}

pre {
  margin-bottom: 0em !important;
  font-family: 'Roboto', sans-serif !important;
}

.gtitle {
  display: none !important;
}

.ant-layout {
  background: #f6f6f8 !important;
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;