.card-preview-map {
  .ant-card-body {
    padding: 5px;
    width: 350px;
  }
  .marker-information {
    width: 340px;
    .marker-header {
      display: flex;
      margin-bottom: 10px;
      .marker-title {
        font-size: 17px;
        font-weight: 600;
      }
      .marker-color {
        margin-left: auto;
        height: 20px;
        width: 80px;
        border-radius: 20px;
      }
    }

    .marker-body-information {
      margin-bottom: 10px;
    }
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;