#google-maps-wrapper {
  width: fit-content;
  height: fit-content;

  .default-maps-wrapper {
    width: 77vw;
    height: 40vh;
  }
  .container-google-maps {
    border: 1px solid rgb(194, 194, 194);
  }
}
.error-display-maps-default {
  border: 1px solid #d8d8d8;
  background: #e5e5e5;
  width: 850px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: #777;
    font-size: 30px !important;
  }
  p {
    margin-top: 10px;
    color: #777;
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;