.form-builder-component {
  .main-title {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 13px;
  }
  .sub-title {
    font-weight: 600;
    margin-bottom: 10px;
  }
  .custom-note-message-uploader {
    color: rgb(0 0 0 / 50%);
    font-size: 10px;
    margin-top: 5px;
  }
  .custom-note-has-error {
    margin-top: 0px !important;
    // display: none;
  }
  .custom-note-no-error {
    margin-top: -20px !important;
    // display: block;
  }
}

.inline-form-table {
  .main-title {
    font-weight: 700;
    font-size: 13px;
  }
  .sub-title {
    font-weight: 600;
  }
  .ant-form-item {
    margin-bottom: 0px !important;
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;