.Workflow__container {
  height: 100vh;

  .Workflow__handler {
    cursor: pointer;
  }
}

.Workflow__card {
  .ant-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.Workflow__form {
  display: flex;
  align-items: center;

  &-input,
  &-input::placeholder {
    font-size: 12px;
  }
}

.Workflow__body {
  &-header {
    display: flex;
    padding-bottom: 0.5rem;

    &-item {
      cursor: pointer;
    }

    &-item:first-child {
      margin-right: auto;
    }

    &-item:nth-of-type(2) {
      margin-right: 0.3rem;
    }
  }

  &-content {
    padding: 0.5rem;
    p {
      margin: 0;
    }
  }
}

.Workflow__popover-button {
  display: flex;
  justify-content: center;

  button {
    width: 80px;
  }

  button:first-child {
    margin-right: 1rem;
  }
}

.Workflow__add-card {
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }

  span {
    font-size: 12px;
  }
}

.is-show {
  display: flex;
}

.is-hidden {
  display: none !important;
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;