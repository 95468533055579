.card__category {
  margin-bottom: 1rem !important;
  &-title {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .card__subcategory-list {
    margin: 0;
  }

  .card__subcategory-item {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 10px;
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;