.body-drawer {
  height: 100%;
  overflow: auto;
  &-details,
  &-description,
  &-people,
  &-dates,
  &-journal,
  &-venue {
    margin-bottom: 18px;
  }

  &-details,
  &-description,
  &-people,
  &-dates,
  &-venue,
  &-journal {
    h4 {
      font-weight: 700;
      font-size: 12px;
    }

    span {
      font-size: 12px;
    }
  }

  &-details {
    .detail-item {
      display: flex;

      span:first-child {
        flex-basis: 100px;
      }

      span:nth-of-type(2) {
        margin-right: 10px;
      }
    }
  }

  &-people {
    .people-item {
      display: flex;

      span:first-child {
        flex-basis: 100px;
      }

      span:nth-of-type(2) {
        margin-right: 10px;
      }
    }
  }

  &-dates {
    .date-item {
      display: flex;

      span:first-child {
        flex-basis: 100px;
      }

      span:nth-of-type(2) {
        margin-right: 10px;
      }
    }
  }

  &-venue {
    .venue-item {
      display: flex;

      span:first-child {
        flex-basis: 100px;
      }

      span:nth-of-type(2) {
        margin-right: 10px;
      }
    }
  }

  &-emails {
    .email-item {
      display: flex;

      span:first-child {
        flex-basis: 100px;
      }

      span:nth-of-type(2) {
        margin-right: 10px;
      }
    }
  }

  &-journal {
    .journal-submit {
      margin-bottom: 10px;
    }

    .journal-submit-button {
      display: flex !important;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 8px;
      }
    }

    .journal-form {
      margin: 40px 0;
    }

    .journal-content {
      &-title {
        .creator-title {
          font-size: 12px;
          font-weight: 700;
        }
        .participant-title {
          margin-bottom: 8px;
          span {
            font-weight: 700;
          }
        }
        .description {
          font-size: 13px;
        }
      }
    }
  }
}

.status {
  &-item {
    font-size: 13px;
    margin-bottom: 10px;
  }

  &-date {
    font-weight: 700;
    display: flex;

    &-item {
      margin-right: 10px;
    }
  }

  &-change {
    p {
      margin: 0;
    }
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;