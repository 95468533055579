.crm-kanban {
  .crm-board {
    min-height: 80vh;
    max-height: 100vh;
    overflow: scroll;
    background: #f8f8f8;
    position: relative;

    .card-area {
      .ant-collapse {
        min-width: fit-content;
      }
      .ant-collapse-borderless > .ant-collapse-item {
        border-bottom: 1px solid #eee;
      }

      .ant-collapse-content-box {
        padding: 0 !important;
        background: #f1f2f4;
      }

      .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 11px 16px;
      }
    }
  }

  .crm-drawer-board {
    min-height: 90vh;
    max-height: 100vh;
    height: 100%;
    background: #fff;
    overflow: auto;
    position: relative;
  }
}

.crm__switcher {
  padding-top: 0.3rem;
  padding-bottom: 1rem;
  padding-right: 1.5rem;
  display: flex;
  justify-content: flex-end;
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;