@import url('@base-styles/variables.less');

.custom-navbar-wrapper {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 10; //FIXME this a hack to fix the z-index issue

  .custom-layout-header {
    // overflow: auto;
    overflow: hidden;
    // position: fixed;
    // top: 0;
    // z-index: 1;
    z-index: 10; //FIXME this a hack to fix the z-index issue

    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid @base-border-color !important;
    background-color: #fff;
    // background-color: #432158;

    // position: -webkit-sticky; /* Safari */
    // position: sticky;
    // width: 100%;
    // top: 0;
  }

  .navbar-custom-menu {
    margin-left: 20px;
    width: 85%;
    height: 100%;
  }

  .no-sidebar {
    width: 100%;
  }

  // .not-collapsed-header {
  //   // width: calc(100% - @base-width-sidebar-not-collapsed);
  //   width: 100%;
  // }

  // .collapsed-header {
  //   // width: calc(100% - @base-width-sidebar-collapsed);
  //   width: 100%;
  // }

  .navbar-right-content {
    display: flex;
    align-items: center;
    margin-left: auto !important;

    .navbar-right-item {
      margin-left: 25px;
      display: flex;
      align-items: center;

      .custom-icon-item {
        font-size: 14px;
      }

      .custom-avatar {
        border: 1px solid @base-border-color !important;
      }
    }
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;