.ant-form-item-label > label {
  font-weight: 400;
  color: #777;
}

.ant-select-selection-placeholder {
  font-size: 11px;
}

*::-webkit-input-placeholder {
  font-size: 11px;
}

*::-moz-placeholder {
  font-size: 11px;
}

*:-ms-input-placeholder {
  font-size: 11px;
}

*::-ms-input-placeholder {
  font-size: 11px;
}

*::placeholder {
  font-size: 11px;
}

//START FOR STYLING BORDER FIELD FORM
// start ant input
.ant-input {
  border: unset;
  border-bottom: 1px solid @input-border-color;
}
.ant-input:focus,
.ant-input-focused {
  box-shadow: none;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  box-shadow: none;
}
//end ant input

//start ant input number

.ant-input-number {
  border: unset;
  border-bottom: 1px solid @input-border-color;
}
.ant-input-number:focus,
.ant-input-number-focused {
  box-shadow: none;
}

.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  box-shadow: none;
}
//end ant input number

//start ant affix wrapper
.ant-input-affix-wrapper {
  border: unset;
  border-bottom: 1px solid @input-border-color;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  box-shadow: none;
}
//end ant affix wrapper

//start ant picker
.ant-picker {
  border: unset;
  border-bottom: 1px solid @input-border-color;
}
.ant-picker:focus,
.ant-picker-focused {
  box-shadow: none;
}

.ant-picker-status-error:not(.ant-picker-disabled):not(.ant-picker-borderless).ant-picker:focus,
.ant-picker-status-error:not(.ant-picker-disabled):not(.ant-picker-borderless).ant-picker-focused {
  box-shadow: none;
}
//end ant picker

//start select
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: unset;
  border-bottom: 1px solid @input-border-color;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
}

//end select

//start select component
.react-select-custom-prefix__control {
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  border-bottom: 1px solid @input-border-color !important;
}
//end select component
//END FOR STYLING BORDER FIELD FORM

//START FOR STYLING MENU ON NAVBAR
// .ant-menu-horizontal > .ant-menu-item a {
//   color: fade(@black, 85%) !important;
// }

// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
//   color: fade(@black, 85%) !important;
// }
// .ant-menu-horizontal > .ant-menu-item-selected a {
//   color: #071e59;
// }

// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
//   color: #071e59;
// }

//END FOR STYLING MENU ON NAVBAR

//START FOR STYLE DATA TABLE
.table-master-style {
  thead[class*='ant-table-thead'] th {
    background-color: @btn-primary-bg !important;
    color: #fff;

    padding-bottom: 3px !important;
    padding-top: 3px !important;
  }
  tr:nth-child(2n):not(.ant-table-row-selected) td {
    background-color: #f9f9f9 !important;
  }
}

.pointer-row {
  tbody tr {
    cursor: pointer;
  }
}

.footer-table {
  height: 40px;
  display: flex;
  align-items: center;
  box-shadow: 0px -2px 0px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px -2px 0px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px -2px 0px -1px rgba(0, 0, 0, 0.1);
}
//END FOR STYLE DATA TABLE

//START FOR STYLE SCROLLBAR

// ::-webkit-scrollbar {
//   width: 10px;
//   height: 10px;
// }

// ::-webkit-scrollbar-track {
//   background-color: #f1f1f1;
// }

// ::-webkit-scrollbar-thumb {
//   background-color: rgba(#333, 0.2);
//   // border: 3px solid transparent;
//   border-radius: 4px;
// }
//END FOR STYLE SCROLLBAR

.avatar-uploader {
  .ant-upload {
    width: 130px;
    height: 130px;
  }
}

.inline-form-table {
  .manual-color-picker {
    .color-picker-wrapper {
      border-bottom: unset;
    }
  }
  .ant-input {
    border: unset !important;
    border-bottom: unset !important;
  }
  .ant-input-number {
    border: unset !important;
    border-bottom: unset !important;
  }
  .ant-input-affix-wrapper {
    border: unset !important;
    border-bottom: unset !important;
  }

  .ant-picker {
    border: unset !important;
    border-bottom: unset !important;
  }

  .react-select-custom-prefix__control {
    border-top: unset !important;
    border-left: unset !important;
    border-right: unset !important;
    border-bottom: unset !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: unset !important;
    border-bottom: unset !important;
  }
  .button-inline-form {
    border-bottom: unset !important;
    &:hover {
      border-bottom: unset !important;
    }
  }
}

.button-inline-form {
  border-bottom: 1px solid @input-border-color !important;
  &:hover {
    border-bottom: 1px solid @input-border-color !important;
  }
}

.custom-note-message {
  color: rgb(0 0 0 / 50%);
  font-size: 10px;
  margin-top: 5px;
}

.custom-error-message {
  color: #f00;
  font-size: 10px;
  margin-top: 5px;
}

.custom-title-section {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 13px;
}

.d-none-input-formula-select {
  &.ant-select-dropdown {
    display: none !important;
  }
}

.custom-label-stye {
  color: #777;
  margin-bottom: 10px;
}

.custom-label-header {
  margin-right: 12px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card-notification {
  .ant-card-body {
    padding: 0;
  }
}

.no-read-notification {
  background: #bae7ff;
  cursor: pointer;
}

.read-notification {
  cursor: pointer;
}

.card-content-wrapper {
  box-shadow: 0px 1px 10px -5px rgba(0, 0, 0, 0.65);
  -webkit-box-shadow: 0px 1px 10px -5px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: 0px 1px 10px -5px rgba(0, 0, 0, 0.65);
}

.card-dashboard-wrapper {
  height: 100%;
  overflow: auto;
}
