.notification {
  width: 270px;
  max-width: 400px;
  background-color: rgb(255, 255, 255);
  border-radius: 2px;

  &-header {
    border-bottom: 1px solid rgb(235, 235, 237);
    padding: 12px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    h1 {
      font-size: 14px;
      margin: 0;
    }
  }

  &-body {
    max-height: 300px;
    overflow: auto;
    margin-left: -16px;
    margin-right: -16px;

    &-no-content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
    }

    &-content:not(:last-child) {
      border-bottom: 1px solid rgba(84, 84, 84, 0.178);
    }

    &-content {
      cursor: pointer;
      background-color: rgb(246, 250, 255);
      padding: 16px;

      &.is-read {
        background-color: rgb(255, 255, 255);
      }

      &:hover {
        background-color: rgb(241, 241, 241);
      }

      .content-notification {
        color: rgb(50, 50, 50);
        margin-bottom: 6px;
        font-size: 10px;
        font-weight: 700;
      }

      .content-item {
        font-size: 11px;
        margin: 0;
      }
    }
  }

  &-footer {
    border-top: 1px solid rgb(235, 235, 237);
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    padding-top: 10px;

    button {
      font-size: 12px;
      font-weight: 700;
    }
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;