.react-draggable-indicator {
  display: flex;
  flex-wrap: wrap;
  .react-draggable,
  .cursor {
    cursor: move;
  }
  .no-cursor {
    cursor: auto;
  }
  .cursor-y {
    cursor: ns-resize;
  }
  .cursor-x {
    cursor: ew-resize;
  }

  .react-draggable strong {
    background: #ddd;
    border: 1px solid #999;
    border-radius: 3px;
    display: block;
    margin-bottom: 10px;
    padding: 3px 5px;
    text-align: center;
  }

  .box {
    background: transparent;
    min-width: 180px;
    min-height: 180px;
    margin: 5px;
    // float: left;
  }
  .no-pointer-events {
    pointer-events: none;
  }
  .hovered {
    background-color: gray;
  }

  /*
     * RemWrapper needs to take it's styles from this element,
     * and this element can't have an absolute position after it's kicked in.
     * AFAIK it's not possible to do this directly in the RemWrapper component.
     */
  .rem-position-fix {
    position: static !important;
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;