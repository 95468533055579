.metric-title {
  font-size: 14px;
  text-align: center;
}

.metric-percentage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.metric-comparison {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  .metric-separator {
    font-size: 24px;
    margin-left: 10px;
    margin-right: 10px;
  }

  &-item {
    flex-basis: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .metric-value {
      font-size: 24px;
      margin: 0;
    }

    .metric-month {
      font-size: 12px;
    }
  }
}

.indicator-component {
  .indicator-title {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  .indicator-data {
    display: flex;
    text-align: center;

    &-item {
      &:last-child &-tag {
        background-color: blue;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }

      &-compare {
        font-size: 28px;
      }

      &-date {
        font-size: 14px;
        margin-bottom: 12px;
      }

      &-tag {
        background-color: red;
        color: white;
        width: 70px;
        margin: 0 auto;
        border-radius: 24px;
      }
    }
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;