.indicator-component {
  min-width: 300px;
  min-height: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  .run-area {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .indicator-data {
    display: flex;
    justify-content: center;
    &-item {
      &:last-child &-tag {
        background-color: blue;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }

      &-compare {
        font-size: 28px;
        position: relative;
        display: flex;
        justify-content: center;

        .indicator-dot {
          width: 10px;
          height: 10px;
          border-radius: 100%;
          margin-top: 6px;
        }

        .indicator-condition {
          position: absolute;
          top: -10px;
          right: 0;
        }
      }

      &-date {
        font-size: 14px;
        margin-bottom: 12px;
      }

      &-tag {
        background-color: red;
        color: white;
        width: 70px;
        margin: 0 auto;
        border-radius: 24px;
      }
    }
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;