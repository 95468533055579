@import '~antd/dist/antd.variable.less';
@import url('@base-styles/variables.less');

.drawer-filter-index {
  .ant-drawer-body {
    padding-top: 5px !important;
  }
}

.custom-page-header {
  border-bottom: 1px solid @base-border-color !important;
  padding: 0px 24 !important;
  overflow: auto;
  // position: fixed;
  top: @layout-header-height;
  z-index: 10; //FIXME this a hack to fix the z-index issue
  // z-index: 1;
  background: #fff !important;
  display: flex;
  align-items: center;

  position: -webkit-sticky; /* Safari */
  position: sticky;
  width: 100%;

  .ant-page-header {
    padding: 5px 24px !important;
  }

  .ant-page-header {
    display: flex;
    align-items: center;
    .ant-page-header-content {
      width: 100%;
      padding-top: 0px !important;
      .ant-input-search-button {
        display: none !important;
        background: transparent !important;
        border-top: unset !important;
        border-left: unset !important;
        border-right: unset !important;
        border-bottom: unset !important;
        color: #fff !important;
      }
    }
  }

  .prefix-content {
    display: flex;
    align-items: center;
  }

  .separator-content {
    height: 47px;
    border-left: 1px solid @base-border-color;
  }

  // &.not-collapsed-header {
  //   // width: calc(100% - @base-width-sidebar-not-collapsed);
  //   width: 100%;
  // }

  // &.collapsed-header {
  //   // width: calc(100% - @base-width-sidebar-collapsed);
  //   width: 100%;
  // }

  &.no-sidebar {
    width: 100%;
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;