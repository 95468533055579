@base-border-color: #eee;

.header-column-board {
  z-index: 1;
  display: flex;
  background: #fff;
  border: 1px solid @base-border-color;
  min-width: fit-content;
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

  position: -webkit-sticky;
  position: sticky;
  top: 0;

  .header-board-column-item {
    min-width: 280px;
    max-width: 280px;
    padding: 15px 10px;
    font-weight: 500;
    border-left: 1px solid @base-border-color;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;