@import '~antd/es/style/themes/default.less';

.auth-login-page {
  .container {
    height: 100vh;
    width: 100vw;
    position: relative;

    .content {
      height: 100vh;
      width: 100vw;
      z-index: 1;
      position: absolute;
    }

    .form-content {
      background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
      background-repeat: no-repeat;
      background-position: center 110px;
      background-size: 100%;

      position: relative;
      height: 99.5vh;
      padding: 10px;
      display: flex;
      align-items: center;

      .form-content-wrapper {
        width: 100%;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 30px;
        padding-bottom: 50px;

        .login-title {
          text-align: center;
          font-size: 20px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
        }
      }
      .ant-card-body {
        width: 100% !important;
      }
    }
  }

  @media (min-width: @screen-md-min) {
    .container {
      background-image: url(../assets/ifgf-dashboard-bg.png);
      background-size: cover;
      background-position: center -80px;

      display: flex;
      align-items: center;
      .content {
        height: 480px;

        .form-content {
          height: 480px;
          background: #fff;

          background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
          background-repeat: no-repeat;
          background-position: center 110px;
          background-size: 100%;
        }
      }
    }
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;