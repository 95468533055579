.recharts-cartesian-grid-horizontal {
  line {
    stroke-dasharray: 2, 2;
    stroke: #d0d0da;
  }
}

.recharts-cartesian-axis-tick-value {
  tspan {
    fill: #a1a1b5;
    letter-spacing: 0.03em;
    // font-weight: bold;
    font-size: 14px;
  }
}

.recharts-cartesian-axis.recharts-yAxis {
  .recharts-cartesian-axis-tick {
  }
}

.recharts-tooltip-cursor {
  fill: rgba(204, 204, 204, 0.2);
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;