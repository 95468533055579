.drawer-form-footer {
  position: absolute;
  bottom: 0;
  padding: 24px 0;
}

.custom-list {
  padding: 14px !important;

  .list-item {
    display: flex;

    &-body {
      margin-right: auto;
    }

    &-header {
      margin-bottom: 9px;
    }

    &-content {
      &-date {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        svg {
          margin-right: 8px;
        }
      }

      &-tag {
        margin-bottom: 10px;
      }

      &-footer {
        span:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }
}

.list-item-action {
  button:first-child {
    // margin-right: 8px;
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;