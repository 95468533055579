.kanban-drawer-detail {
  .ant-drawer-body {
    padding: 0 !important;
  }
}

.detail-body-general {
  height: 100%;
  padding: 8px 15px;
}

.detail-body-headers {
  margin-bottom: 12px;

  h4 {
    font-size: 14px;
    margin: 0;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    margin: 0;
  }
}

.detail-body:not(:last-child) {
  margin-bottom: 12px;
}

.detail-body {
  p {
    font-size: 13px;
  }

  &-title {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 6px;
  }

  &-item {
    display: flex;
    font-size: 13px;

    span:first-child {
      flex-basis: 100px;
    }

    span:nth-of-type(2) {
      margin-right: 10px;
    }
  }
}

.detail-journal {
  height: 100%;
  padding: 8px 15px;

  &-add {
    margin-bottom: 10px;
  }

  &-add-button {
    display: flex !important;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 8px;
    }
  }

  &-form {
    margin: 30px 0;
  }

  &-body {
    &-creator {
      font-weight: 700;
    }

    &-participant {
      margin-bottom: 6px;
      span {
        font-weight: 700;
      }
    }
  }
}

.detail-status {
  &-item {
    font-size: 13px;
    margin-bottom: 10px;
  }

  &-date {
    font-weight: 700;
  }

  &-change {
    p {
      margin: 0;
    }
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 6px;@border-radius-sm: 6px;